<template>
    <v-container grid-list-lg fluid>

        <!--Datatable Toolbar-->
        <v-toolbar flat color="white" elevation="1" class="custom_datatable_toolbar" height="56">
            <v-toolbar-title>{{title}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="green" dark class="custom_button mobile_button" :title="add_button_title"
                   @click="showItemDialog">
                <v-icon small size="20">add</v-icon>
            </v-btn>
        </v-toolbar>

        <!--Datatable-->
        <ResponsiveDataTable :headers="headers" :items="items" :loading="loading"
                             :mobileBreakpoint="mobile_breakpoint">
            <!--Buttons-->
            <template v-slot:buttons="{item}">
                <div class="actions">
                    <v-icon color="primary" :title="$t('general.view')" @click="showViewItemDialog(item)">view_list
                    </v-icon>
                    <v-icon color="primary" :title="$t('general.update')" @click="showEditItemDialog(item)">edit
                    </v-icon>
                    <v-icon color="primary" :title="$t('general.delete')" @click="showDeleteItemDialog(item)">delete
                    </v-icon>
                </div>
            </template>
        </ResponsiveDataTable>

        <!--Delete tag dialog-->
        <StatusDialog :show="show_delete_item_dialog" :header="delete_text" :text="delete_confirmation_text"
                      color="black" :is_loading="delete_item_dialog_loading" @confirm="deleteItem"
                      @close="closeDeleteItemDialog">
        </StatusDialog>

    </v-container>
</template>

<script>
    import ResponsiveDataTable from '../components/ResponsiveDataTable.vue'
    import StatusDialog from '../components/StatusDialog.vue'

    export default {
        components: {
            ResponsiveDataTable, StatusDialog
        },
        props: {
            title: {type: String},
            headers: {type: Array},
            items: {type: Array},
            loading: {type: Boolean},
            url: {type: String},
            add_button_title: {type: String},
            delete_text: {type: String},
            delete_confirmation_text: {type: String},
            mobile_breakpoint: {type: Number, default: 600}
        },
        data: () => ({
            show_delete_item_dialog: false,
            delete_item_dialog_loading: false,
            item_id: null
        }),
        methods: {
            // trigger parent event to show item dialog
            showItemDialog() {
                this.$emit('show-dialog', null, 'create');
            },
            // trigger parent event to show view item dialog
            showViewItemDialog(prop) {
                this.$emit('view-dialog', prop.item, 'view');
            },
            // trigger parent event to show update item dialog
            showEditItemDialog(prop) {
                this.$emit('update-dialog', prop.item, 'update');
            },
            // make API call to create new item
            async submitItem(item, id) {
                return await this.$store.dispatch('apiRequest', {
                    method: id ? 'patch' : 'post', url: id ? `${this.url}/${id}` : this.url, data: item
                });
            },
            // show delete item dialog
            showDeleteItemDialog(prop) {
                this.show_delete_item_dialog = true;
                this.item_id = prop.item.id;
            },
            // close delete item dialog
            closeDeleteItemDialog() {
                this.show_delete_item_dialog = false;
                this.item_id = null;
            },
            // delete dialog
            async deleteItem() {
                this.delete_item_dialog_loading = true;

                let response = await this.$store.dispatch('apiRequest', {
                    method: 'delete', url: `${this.url}/${this.item_id}`
                });

                this.delete_item_dialog_loading = false;

                // successful response
                if (!response.response) {

                    // close dialog
                    this.closeDeleteItemDialog();

                    // trigger parent event to get items
                    this.$emit('get-data');
                }
            }
        }
    }
</script>