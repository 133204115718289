<template>
    <div>
        <SettingsList ref="child" :title="$t('categories.plural')" :headers="headers" :items="items"
                      :loading="loading" :url="url" :add_button_title="$t('categories.create')"
                      :delete_text="$t('categories.delete')"
                      :delete_confirmation_text="$t('categories.delete_confirmation')" @show-dialog="showDialog"
                      @view-dialog="showDialog" @update-dialog="showDialog" @get-data="getData">
        </SettingsList>

        <!--Tag Dialog-->
        <Dialog :show="show_item_dialog" max_width="50%" :header="item_dialog_header"
                :is_loading="item_dialog_loading" :action="item_action" @close-dialog="closeItemDialog"
                @submit-dialog="submitItemDialog">
            <template slot="content">
                <v-form autocomplete="off" class="white_bg_form">
                    <v-row dense>
                        <v-col cols="12">
                            <v-text-field name="category" type="text" class="custom_field" v-model="item.category"
                                          :label="$t('general.description')" outlined clearable :disabled="isDisabled"
                                          @input="$v.item.category.$touch()" @blur="$v.item.category.$touch()"
                                          :error-messages="fieldErrors('category')">
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </template>
        </Dialog>
    </div>
</template>

<script>
    import SettingsList from '../../components/SettingsList.vue'
    import Dialog from '../../components/Dialog.vue'
    import {validationMixin} from 'vuelidate'
    import {required, numeric} from 'vuelidate/lib/validators'

    export default {
        components: {
            SettingsList, Dialog
        },
        mixins: [validationMixin],
        validations: {
            item: {
                category: {required}
            }
        },
        data: self => ({
            // datatable
            headers: [],
            items: [],
            loading: false,
            url: 'product_category',

            // dialog
            show_item_dialog: false,
            item_dialog_header: null,
            item_dialog_loading: false,
            item: {
                category: null
            },
            item_id: null,
            item_action: null
        }),
        computed: {
            isDisabled() {
                return this.item_action === 'view';
            },
            locale() {
                return this.$i18n.locale;
            }
        },
        created() {
            this.headers = this.getHeaders();
        },
        async mounted() {
            this.getData();
        },
        watch: {
            locale(val) {
                this.headers = this.getHeaders();
            }
        },
        methods: {
            // return headers array
            getHeaders() {
                return [
                    {text: `${this.$t('general.description')}`, value: 'category', sortable: true},
                    {text: `${this.$t('datatable.actions')}`, value: 'actions', align: 'right', sortable: false}
                ];
            },

            // form field errors
            fieldErrors(prop) {
                const errors = [];
                if (!this.$v.item[prop].$dirty) return errors;
                !this.$v.item[prop].required && errors.push(this.$t('validation.required'));
                return errors;
            },
            // get data from API
            async getData() {
                this.loading = true;
                let response = await this.$store.dispatch('apiRequest', {
                    method: 'get',
                    url: `${this.url}/index`
                });

                // successful response
                if (!response.response) {
                    this.items = response.data;
                }

                this.loading = false;
            },
            // show item dialog
            showDialog(item, action) {
                this.show_item_dialog = true;
                this.item_action = action;
                this.item_dialog_header = action === 'create' ? this.$t('categories.create') :
                    action === 'view' ? this.$t('categories.view') : this.$t('categories.update');

                if (item) {
                    this.setItemInfo(item);
                }
            },
            // set item info for update and view
            setItemInfo(item) {
                this.item_id = item.id;

                for (let prop in this.item) {
                    this.item[prop] = item[prop];
                }
            },
            // close item dialog
            closeItemDialog() {
                this.$v.item.$reset();
                this.show_item_dialog = false;
                this.item_id = null;
                this.item_action = null;

                for (let prop in this.item) {
                    this.item[prop] = null;
                }
            },
            // submit item dialog
            async submitItemDialog() {
                this.$v.item.$touch();

                // login data are valid
                if (!this.$v.item.$invalid) {
                    this.item_dialog_loading = true;
                    let item = Object.assign({}, this.item);

                    let response = await this.$refs.child.submitItem(item, this.item_id);

                    // stop dialog submit button loading
                    this.item_dialog_loading = false;

                    // successful response
                    if (!response.response) {

                        // close item dialog
                        this.closeItemDialog();

                        // get items
                        this.getData();
                    }
                }
            }
        }
    }
</script>